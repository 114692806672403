const regroupingAddUsersModal = () => {
  const submitUser = document.getElementById('submit_invite_users')
  const inputUser = document.querySelector("#add_users_regrouping ul")
  if (inputUser) {
    inputUser.addEventListener('DOMSubtreeModified', () => {
      const nbr = document.querySelectorAll("#add_users_regrouping .select2-selection__choice").length
      if (nbr == 0) {
        submitUser.setAttribute("disabled", "disabled")
      } else {
        submitUser.removeAttribute("disabled")
      }
    })
  }
}


export { regroupingAddUsersModal }